<!-- Import other components used here -->
<import name="RouterLink" from="rmlibrary/router" />
<import name="Fragment" from="rmlibrary/comp" />
<import name="FloatingCard" from="./banners/floating-card.html" />

<!-- Add styles for this component -->
<style lang="scss">
    @import 'sass/variables';

    .careers {
        font-size: 30px;
        text-align: center;
        margin: 0 auto;
        padding: 0px 30px;
        font-weight: 700;
        color: #black;
        position: relative;
        top:50%;
        @media #{$md-up} {
            font-size: 50px;
        }
    }
    .careers-description {
        text-align:center;
        margin-top:10px;
        padding: 0px 30px;
        font-size:20px;
    }
    .hero-video {
        background-image: url(images/hero-video-p.jpg);
        background-size: cover;
        width: 100%;
        height: 300px;
        margin: 0 auto;
        background-position: center;
        @media #{$md-up} {
            height: 700px;
        }
    }

    .yellow-gradient {
        padding: 50px 0px;
        @media #{$xs-only, $sm-only, $md-only} {
            padding-bottom:15px;
        }
        background: radial-gradient(circle, #FCCB5E, $yellow, $yellow, $yellow, $yellow, $yellow);
        h2 {
            animation-name: fadeIn;
            animation-duration: 1s;
        }
        p.careers-description {
            animation-name: fadeIn;
            animation-duration: 1s;
        }
    }

    .perks-banner {
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        @media #{$lg-up} {
            flex-direction: row;
            max-width: 1100px;
            justify-content: center;
        }
        .icon-text {
            padding: 30px 0px;
            width: 100%;
            display: flex;
            @media #{$xs-only, $sm-only, $md-only} {
                align-items:center;
            }
            @media #{$xs-up} {
                &:nth-child(-n+3) {
                    border-bottom: 1px solid $black90;
                }
            }
            @media #{$lg-up} {
                height:230px;
                &:nth-child(3) {
                    border-bottom: none;
                }
                &:nth-child(4) {
                    border-bottom: none;
                }
            }
            @media #{$lg-up} {
                padding: 50px 0px;
                width: 45%;
                max-width: 400px;
                &:nth-child(odd) {
                    margin-right: 50px;
                }
            }
            .perks-title {
                font-size: 20px;
                margin-bottom:5px;
                font-weight: 700;
                color: $black90;
                @media #{$xs-only} {
                    font-size: 18px;
                }
                @media #{$xs-only, $sm-only, $md-only} {
                    margin-bottom:15px;
                }
            }
            .perks-text {
                font-size: 16px;
                color: $black90;
                @media #{$xs-only} {
                    font-size:14px;
                }
            }
            .perks-icon {
                margin-right: 20px;
                @media #{$xs-only} {
                    max-width:50px;
                    margin-right:15px;
                }
            }
        }
    }


    .job-applications {
        background-color:$black90;
        width: 100%;
        height: 200px;

        @media #{$xs-only, $sm-only} {
            height:auto;
            padding-bottom:20px;
            padding-right:20px;
            padding-left:20px;
        }

        .positions {
            display:flex;
            justify-content:center;
            /*max-width:600px; for 2 postings */
            max-width: 910px;
            margin:0 auto;
            align-items:center;
            @media #{$xs-only, $sm-only} {
                flex-wrap:wrap;
                justify-content:flex-start;
            }
        }
        .divider {
            width:1px;
            background-color:$yellow;
            height:160px;
            margin-top:20px;
            flex-shrink:0;
            @media #{$xs-only, $sm-only} {
                width:100%;
                height:1px;
            }
        }
        .application {
            width:50%;
            padding-right:30px;
            padding-left:40px;
            min-width: 340px;

            @media #{$xs-only, $sm-only} {
                width:100%;
                padding:40px 0 40px 0;
            }
            @media #{$md-only} {
                padding-right:35px;
                padding-left:35px;
                min-width: 33%;
            }
            h3 {
                color:white;
                font-size:30px;
                margin-bottom:25px;
                text-align:left;
                margin-left: 17.5px;
                /* margin-right: 5px; */

                @media #{$md-only} {
                    font-size:23px;
                    /* height:54px; if theres no graphic designer & copy writer */
                    height:85px;
                    margin-left:0;
                }
            }
        }
        .less-than-three-positions-margin {
            margin-top: 25px;
        }
        .less-than-three-positions-width {
            max-width: 660px;
        }
        .more-than-three-positions-margin {
            padding-left:10px;
            padding-right:10px;

            h3 {
                font-size:27px;
            }
        }
        .more-than-three-positions-width {
            max-width: 1080px;
        }
    }

    .gallery-row {
        display: flex;
        height:200px;
        @media #{$xs-only} {
            height:150px;
        }
        > .gallery-column {
            display: flex;
            flex-direction: column;
            height:100%;

            > * {
                flex: 1 1 auto;
            }
        }
        .photo-tile {
            overflow: hidden;
            position: relative;
            > * {
                position: absolute;
                top:0;
                left:0;
                right:0;
                bottom:0;
                background-size: cover;
                background-position:center;
                background-repeat: no-repeat;
                transition: all 1s ease;
                &:hover {
                    transform: scale(1.3) rotate(0.1deg);
                }
            }
        }
    }

    .video {
        position: relative;
        background-size: cover;
        background-position: center;
        background-repeat:no-repeat;
        overflow-x:hidden;
        @media #{$xs-up}{
            background-image: url(images/banner-bgs/careers-banner-static-md.jpg);
            padding-bottom: 56.25%;
            &-halloween {
                background-image: url(images/banner-bgs/halloween-careers-banner-static-md.jpg)
            }
            &-xmas {
                background-image: url(images/banner-bgs/xmas-careers-banner-static-md.jpg)
            }
            &-pride {
                background-image: url(images/banner-bgs/pride-careers-banner-static-md.jpg)
            }
        }

        @media #{$sm-up}{
            background-image: url(images/banner-bgs/careers-banner-static-md.jpg);
            &-halloween {
                background-image: url(images/banner-bgs/halloween-careers-banner-static-md.jpg)
            }
            &-xmas {
                background-image: url(images/banner-bgs/xmas-careers-banner-static-md.jpg)
            }
            &-pride {
                background-image: url(images/banner-bgs/pride-careers-banner-static-md.jpg)
            }
        }
        @media #{$md-up}{
            background-image: url(images/banner-bgs/careers-banner-static-lg.jpg);
            padding-bottom: 41%;
            &-halloween {
                background-image: url(images/banner-bgs/halloween-careers-banner-static-lg.jpg)
            }
            &-xmas {
                background-image: url(images/banner-bgs/xmas-careers-banner-static-lg.jpg)
            }
            &-pride {
                background-image: url(images/banner-bgs/pride-careers-banner-static-lg.jpg)
            }
        }
        @media #{$lg-up}{
            padding-bottom: 41%;
            background-image: url(images/banner-bgs/careers-banner-static-lg.jpg);
            &-halloween {
                background-image: url(images/banner-bgs/halloween-careers-banner-static-lg.jpg)
            }
            &-xmas {
                background-image: url(images/banner-bgs/xmas-careers-banner-static-lg.jpg)
            }
            &-pride {
                background-image: url(images/banner-bgs/pride-careers-banner-static-lg.jpg)
            }
        }
        @media #{$xl-up} {
            min-height:calc(100vh - 270px);
            background-image: url(images/banner-bgs/careers-banner-static-lg2.jpg);
            background-size: cover;
            padding-bottom:0;
            overflow-y:hidden;
        }
        .left-img {
            position:absolute;
            left:-82px;
            transform: rotate(270deg);
            top:30%;
            display:none;
            @media #{$xl-up} {
                display:block;
            }
            img {
                width:380px;
            }
        }
        .right-img {
            position:absolute;
            right:-85px;
            transform: rotate(90deg);
            top:30%;
            display:none;
            @media #{$xl-up} {
                display:block;
            }
            img {
                width:400px;
            }
        }
        .top1, .top2, .top3 {
            position:absolute;
            top:0;
            display:none;
            @media #{$xl-up} {
                display:block;
            }
            img {
                width:400px;
            }
        }
        .top2 {
            img {
                width:500px;
            }
        }
        .top2, .bottom2 {
            left:35%;
        }
        .top3, .bottom3 {
            left:70%;
        }
        .bottom1, .bottom2, .bottom3 {
            position:absolute;
            bottom:0;
            transform: rotate(180deg);
            display:none;
            @media #{$xl-up} {
                display:block;
            }
            img {
                width:400px;
            }
        }
        .bottom2, .bottom1 {
            img {
                width:300px;
            }
        }
        .bottom2 {
            left:40%;
        }
        h2 {
            font-size: 35px;
            text-align: center;
            margin: 0 auto;
            font-weight: 700;
            color: #fff;
            position: absolute;
            top:29%;
            left: 50%;
            width:100%;
            transform: translateX(-50%);
            animation-name: fadeIn2;
            animation-duration: 1s;
            @media #{$md-up} {
                font-size: 50px;
                top: 35%;
            }
            @media #{$xs-only} {
                font-size:19px;
            }
        }

        video {
            width:100%;
            height:100%;
            z-index: 1;
        }

        .social-icons {
            display:flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 45%;
            left: 50%;
            transform: translateY(-50%);
            transform: translateX(-50%);
            z-index: 2;
            animation-name: fadeIn2;
            animation-duration: 1s;
            @media #{$lg-only, $md-only} {
                top: 50%;
            }
            @media #{$sm-only} {
                top:43%;
            }
            @media #{$xs-only} {
                top:38%;
            }
            a {
                margin:10px;
                cursor:pointer;
            }
            .bg-img {
                width:27px;
                height:25px;
                background-size:cover;
                margin:10px;
                transition:background-image 0.5s ease-in-out;
                @media #{$xs-only} {
                    width:20px;
                    height:20px;
                }
            }
            .linkedin {
                background-image: url(images/icons/SVG/linkedin-w.svg);
                &:hover {
                    background-image: url(images/icons/SVG/linkedin-y.svg);
                    transition:background-image 0.5s ease-in-out;
                }
            }
            .facebook {
                background-image: url(images/icons/SVG/facebook-w.svg);
                &:hover {
                    background-image: url(images/icons/SVG/facebook-y.svg);
                    transition:background-image 0.5s ease-in-out;
                }
            }
            .twitter {
                background-image: url(images/icons/SVG/twitter-w.svg);
                &:hover {
                    background-image: url(images/icons/SVG/twitter-y.svg);
                    transition:background-image 0.5s ease-in-out;
                }
            }
            .instagram {
                background-image: url(images/icons/SVG/instagram-w.svg);
                &:hover {
                    background-image: url(images/icons/SVG/instagram-y.svg);
                    transition:background-image 0.5s ease-in-out;
                }
            }
        }
    }


     .nowrap {
        white-space: nowrap;
    }

    .content {
        max-width: 1100px;
        display: block;
        margin: 0 auto;
        padding-left: 20px;
        padding-right:20px;
    }


</style>

<template name="PhotoTile" extends="PhotoTileController">
    <this.TileComponent href="{this.href}" target={this.target} className="{joinClasses(this.props.className, 'photo-tile')}" aria-label="{this.props.ariaLabel}">
        <div style.backgroundImage="{`url('${this.props.src}')`}" style.backgroundPosition="{this.props.position ? this.props.position : 'center'}"
             style.cursor="{this.props.url ? 'pointer' : 'auto'}"></div>
    </this.TileComponent>
</template>
<script>
class PhotoTileController extends Component {
    constructor(props) {
        super(props);
    }
    get TileComponent() {
        return (this.props.url) ? 'a' : 'div';
    }

    get href() {
        return this.props.url;
    }
    get target() {
        return this.props.url ? '_blank' : undefined;
    }
}
</script>


<template name="Careers__">
    <main>
        <div class="video"
            class.video-halloween="{this.props.theme === 'halloween'}"
            class.video-pride="{this.props.theme === 'pride'}"
            class.video-xmas="{this.props.theme === 'xmas' || this.props.theme === 'new-year'}"
        >
            
            <!-- No Holiday specifications -->
            <If c="{this.props.theme === 'default' || this.props.theme === 'redacted' || this.props.theme === 'canadaday'}">
                <Fragment>
                    <div class="left-img"><img src="site_assets/images/banner-bgs/careers-iphone-maxi.png" alt="person holding smartphone"/></div>
                    <div class="top1"><img src="site_assets/images/banner-bgs/careers-legos.png" alt="person playing with LEGO"/></div>
                    <div class="top2"><img src="site_assets/images/banner-bgs/careers-iphone-jason.png" alt="person holding smartphone"/></div>
                    <div class="top3"><img src="site_assets/images/banner-bgs/careers-notebook.png" alt="person writing in notebook"/></div>
                    <div class="right-img"><img src="site_assets/images/banner-bgs/careers-play-dough.png" alt="person playing with playdough"/></div>
                    <div class="bottom1"><img src="site_assets/images/banner-bgs/careers-ipad-male.png" alt="person holding tablet"/></div>
                    <div class="bottom2"><img src="site_assets/images/banner-bgs/careers-controler.png" alt="person holding game controller"/></div>
                    <div class="bottom3"><img src="site_assets/images/banner-bgs/careers-ipad-paula.png" alt="person holding tablet"/></div>
                </Fragment>
            </If>

            <!-- Halloween -->
            <If c="{this.props.theme === 'halloween'}">
                <Fragment>
                    <div class="left-img"><img src="site_assets/images/banner-bgs/careers-iphone-maxi.png" alt="person holding smartphone"/></div>
                    <div class="top1"><img src="site_assets/images/banner-bgs/careers-legos.png" alt="person playing with LEGO"/></div>
                    <div class="top2"><img src="site_assets/images/banner-bgs/halloween-careers-iphone-jason.png" alt="person holding smartphone"/></div>
                    <div class="top3"><img src="site_assets/images/banner-bgs/careers-notebook.png" alt="person writing in notebook"/></div>
                    <div class="right-img"><img src="site_assets/images/banner-bgs/careers-play-dough.png" alt="person playing with playdough"/></div>
                    <div class="bottom1"><img src="site_assets/images/banner-bgs/careers-ipad-male.png" alt="person holding tablet"/></div>
                    <div class="bottom2"><img src="site_assets/images/banner-bgs/careers-controler.png" alt="person holding game controller"/></div>
                    <div class="bottom3"><img src="site_assets/images/banner-bgs/careers-ipad-paula.png" alt="person holding tablet"/></div>
                </Fragment>
            </If>

            <!-- Christmas -->
            <If c="{this.props.theme === 'xmas' || this.props.theme === 'new-year'}">
                <Fragment>
                    <div class="left-img"><img src="site_assets/images/banner-bgs/careers-iphone-maxi.png" alt="person holding smartphone"/></div>
                    <div class="top1"><img src="site_assets/images/banner-bgs/xmas-careers-legos.png" alt="person playing with LEGO"/></div>
                    <div class="top2"><img src="site_assets/images/banner-bgs/xmas-careers-iphone-jason.png" alt="person holding smartphone"/></div>
                    <div class="top3"><img src="site_assets/images/banner-bgs/xmas-careers-notebook.png" alt="person writing in notebook"/></div>
                    <div class="right-img"><img src="site_assets/images/banner-bgs/xmas-careers-play-dough.png" alt="person playing with playdough"/></div>
                    <div class="bottom1"><img src="site_assets/images/banner-bgs/xmas-careers-ipad-male.png" alt="person holding tablet"/></div>
                    <div class="bottom2"><img src="site_assets/images/banner-bgs/careers-controler.png" alt="person holding game controller"/></div>
                    <div class="bottom3"><img src="site_assets/images/banner-bgs/careers-ipad-paula.png" alt="person holding tablet"/></div>
                </Fragment>
            </If>

             <!-- Pride -->
             <If c="{this.props.theme === 'pride'}">
                <Fragment>
                    <div class="left-img"><img src="site_assets/images/banner-bgs/careers-iphone-maxi.png" alt="person holding smartphone"/></div>
                    <div class="top1"><img src="site_assets/images/banner-bgs/careers-legos.png" alt="person playing with LEGO"/></div>
                    <div class="top2"><img src="site_assets/images/banner-bgs/careers-iphone-jason.png" alt="person holding smartphone"/></div>
                    <div class="top3"><img src="site_assets/images/banner-bgs/careers-notebook.png" alt="person writing in notebook"/></div>
                    <div class="right-img"><img src="site_assets/images/banner-bgs/careers-play-dough.png" alt="person playing with playdough"/></div>
                    <div class="bottom1"><img src="site_assets/images/banner-bgs/careers-ipad-male.png" alt="person holding tablet"/></div>
                    <div class="bottom2"><img src="site_assets/images/banner-bgs/pride-careers-controler.png" alt="person holding game controller"/></div>
                    <div class="bottom3"><img src="site_assets/images/banner-bgs/careers-ipad-paula.png" alt="person holding tablet"/></div>
                </Fragment>
            </If>

            <h2 class="careers">Join the Rich Media team</h2>
            <div class="social-icons">
                <a href="{socialLinks.linkedin}" target="_blank" aria-label="Rich Media on Linkedin"><div class="linkedin bg-img"></div></a>
                <a href="{socialLinks.instagram}" target="_blank"aria-label="Rich Media on Instagram"><div class="instagram bg-img"></div></a>
                <a href="{socialLinks.facebook}" target="_blank"aria-label="Rich Media on Facebook"><div class="facebook bg-img"></div></a>
                <a href="{socialLinks.twitter}" target="_blank"aria-label="Rich Media on X"><div class="twitter bg-img"></div></a>
            </div>
            <!--<video preload="true" autoPlay loop muted>
                <source src="site_assets/images/careers.mp4" type="video/mp4"/>
            </video>-->
        </div>

        <div class="yellow-gradient">
            <h2 class="careers">What it is like to work here:</h2>
            <p class="careers-description">We’re <span class="redact2"> a dynamic group of </span>dedicated workers, learners, artists, and <span class="nowrap">fun-havers</span><span class="redact2"> – come grow with us!</span></p>
            <div class="content">
                <div class="perks-banner">
                    <div class="icon-text">
                        <span><img width="70px" height="70px" class="perks-icon" src="site_assets/images/icons/SVG/health-benefits-icon-black.svg" aria-labelledby="health-benefits-icon"></span>
                        <span>
                            <p class="perks-title" id="health-benefits-icon">Health Benefits</p>
                            <p class="perks-text">Like all Rich Media employees, you’ll enjoy<span class="redact"> health and dental benefits, and be known throughout the industry for</span> your <span class="redact">bright smile, clear vision, and </span>consistently scurvy-free bill of health.</p>
                        </span>
                    </div>
                    <div class="icon-text">
                            <span><img width="70px" height="70px" class="perks-icon" src="site_assets/images/icons/SVG/flexible-hours-icon-black.svg" aria-labelledby="flex-time-icon"></span>
                            <span>
                            <p class="perks-title" id="flex-time-icon">Flexible Hours</p>
                            <p class="perks-text"><span class="redact">Work on a schedule that works for you.</span> Start your day as early as 8 or as late as 10, and eight hours <span class="redact">(including lunch) later, be on your way. We believe you should be able</span> to choose your favourite traffic jam to be stuck in.</p>
                            </span>
                    </div>
                    <div class="icon-text">
                            <span><img width="70px" height="70px" class="perks-icon" src="site_assets/images/icons/SVG/vacation-icon-black.svg" aria-labelledby="vacation-icon"></span>
                            <span>
                                <p class="perks-title" id="vacation-icon">3 Weeks Vacation</p>
                                <p class="perks-text"><span class="redact">We love what we do, but we also love to do other things too. You’ll get at least three weeks off per year, during which</span> you can climb a mountain, learn to tango, sit perfectly still, or do… anything, really. <span class="redact">We can’t tell you what to do with them. That’s the point.</span></p>
                            </span>
                    </div>
                    <div class="icon-text">
                            <span><img width="70px" height="70px" class="perks-icon" src="site_assets/images/icons/SVG/location-icon-black.svg" aria-labelledby="location-icon"></span>
                            <span>
                                <p class="perks-title" id="location-icon">Location</p>
                                <p class="perks-text">Situated at the corner of <span class="redact">Don Mills &amp; Lawrence,</span> you’ll be a stone’s throw from the <span class="redact">DVP*, have three bus lines to choose from,</span> and have the Shops at <span class="redact2">Don Mills at your disposal.</span></p>
                                <span class="redact">* - Please do not throw stones at the DVP</span>
                                
                            </span>
                    </div>
            </div>
            </div>
        </div>


        <!-- Changing open job positions should be done through the assets/data/open-careers.json file ONLY -->
        <div class="job-applications">
            <div class="positions" class.less-than-three-positions-width="{this.props.openings < 3}" class.more-than-three-positions-width="{this.props.openings > 3}">
                <If c="{this.props.accountmanager}">
                    <div class="application" class.less-than-three-positions-margin="{this.props.openings == 1}" class.more-than-three-positions-margin="{this.props.openings > 3}">
                        <h3>Digital Marketing Sales</h3>
                        <RouterLink to="/careers/accountmanager" target="_blank" class="careers-btn yellow-button">LEARN MORE <span class="chevron right"></span></RouterLink>
                    </div>
                </If>

                <If c="{this.props.accountmanager && this.props.designer}">
                    <div class="divider" data-value="designer"></div>
                </If>

                <If c="{this.props.designer}">
                    <div class="application" class.less-than-three-positions-margin="{this.props.openings == 1}" class.more-than-three-positions-margin="{this.props.openings > 3}">
                        <h3>Graphic Designer</h3>
                        <RouterLink to="/careers/graphicdesigner" class="careers-btn yellow-button">LEARN MORE <span class="chevron right"></span></RouterLink>
                    </div>
                </If>
                
                <If c="{this.props.designercopy && (this.props.designer || this.props.accountmanager)}">
                    <div class="divider" data-value="designercopy"></div> 
                </If>

                <If c="{this.props.designercopy}">
                    <div class="application" class.less-than-three-positions-margin="{this.props.openings == 1}" class.more-than-three-positions-margin="{this.props.openings > 3}">
                        <h3>Graphic Designer &amp; Copy Writer</h3>
                        <RouterLink to="/careers/designercopy" target="_blank" class="careers-btn yellow-button">LEARN MORE <span class="chevron right"></span></RouterLink>
                    </div>
                </If>

                <If c="{this.props.qaanalyst && (this.props.designercopy || this.props.designer || this.props.accountmanager)}">
                    <div class="divider" data-value="qaanalyst"></div>
                </If>

                <If c="{this.props.qaanalyst}">
                    <div class="application" class.less-than-three-positions-margin="{this.props.openings == 1}" class.more-than-three-positions-margin="{this.props.openings > 3}">
                        <h3>Junior Digital QA Analyst</h3>
                        <RouterLink to="/careers/qaanalyst" target="_blank" class="careers-btn yellow-button">LEARN MORE <span class="chevron right"></span></RouterLink>
                    </div>
                </If>

                <If c="{this.props.developer && (this.props.qaanalyst || this.props.designercopy || this.props.designer || this.props.accountmanager)}">
                    <div class="divider" data-value="developer"></div>
                </If>

                <If c="{this.props.developer}">
                    <div class="application" class.less-than-three-positions-margin="{this.props.openings == 1}" class.more-than-three-positions-margin="{this.props.openings > 3}">
                        <h3>Web &amp; Mobile Developer</h3>
                        <RouterLink to="/careers/programmer" target="_blank" class="careers-btn yellow-button">LEARN MORE <span class="chevron right"></span></RouterLink>
                    </div>
                </If>

                <If c="{this.props.internship && (this.props.developer || this.props.qaanalyst || this.props.designercopy || this.props.designer || this.props.accountmanager)}">
                    <div class="divider" data-value="internship"></div>
                </If>

                <If c="{this.props.internship}">
                    <div class="application" class.less-than-three-positions-margin="{this.props.openings == 1}" class.more-than-three-positions-margin="{this.props.openings > 3}">
                        <h3>Co-op &amp; Internships</h3>
                        <RouterLink to="/careers/internship" class="careers-btn yellow-button">LEARN MORE <span class="chevron right"></span></RouterLink>
                    </div>
                </If>

                <If c="{this.props.openings == 0}">
                    <div class="application" class.less-than-three-positions-margin="{this.props.openings == 0}">
                        <h3>No posting?<br />Apply anyway</h3>
                        <RouterLink to="/careers/apply" class="careers-btn yellow-button">LEARN MORE <span class="chevron right"></span></RouterLink>
                    </div> 
                </If>
            </div>
        </div>

        <div class="photo-gallery">
            <div class="gallery-row">
                <PhotoTile src="site_assets/images/images-careers/01-01.jpg" class="d-none d-md-block w-3-10" />
                <div class="gallery-column w-4-10 w-md-2-10">
                    <PhotoTile src="site_assets/images/linkedin.jpg" url="{socialLinks.linkedin}" ariaLabel="social link, linkedIn"/>
                    <PhotoTile src="site_assets/images/images-careers/02-01.jpg" position="top" />
                </div>
                <PhotoTile src="site_assets/images/images-careers/03-01.jpg" class="d-none d-md-block w-3-20" />
                <PhotoTile src="site_assets/images/images-careers/04-01.jpg" class="w-6-10 w-md-7-20" />
            </div>
            <div class="gallery-row">
                <div class="gallery-column d-none d-md-flex-column w-3-20">
                    <PhotoTile src="site_assets/images/images-careers/05-01.jpg" position="top"/>
                    <PhotoTile src="site_assets/images/images-careers/06-01.jpg" />
                </div>
                <PhotoTile src="site_assets/images/images-careers/07-01.jpg" class="w-6-10 w-md-4-10" />
                <div class="gallery-column w-4-10 w-md-3-20">
                    <PhotoTile src="site_assets/images/images-careers/10-01.jpg" position="top" />
                    <PhotoTile src="site_assets/images/instagram.jpg" url="{socialLinks.instagram}" ariaLabel="social link, instagram"/>
                </div>
                <PhotoTile src="site_assets/images/images-careers/09-01.jpg" position="top" class="d-none d-md-block w-3-10" />
            </div>
        </div>
    </main>

</template>
<script>

    
import {CustomizationConsumer} from '../common/customization';

export function Careers(props) {
    return (<CustomizationConsumer>
        {(customizationState) => <Careers__ {...props} 
        theme={customizationState.theme}
        accountmanager={customizationState.accountmanager}
        designer={customizationState.designer}
        designercopy={customizationState.designercopy}
        developer={customizationState.developer}
        internship={customizationState.internship}
        qaanalyst={customizationState.qaanalyst}
        openings={customizationState.openings}
        />}
    </CustomizationConsumer>)
}

const socialLinks = {
    linkedin: "https://www.linkedin.com/company/rich-media",
    instagram: "https://www.instagram.com/richmedia.social/",
    facebook: "https://www.facebook.com/Rich-Media-251114581758676/",
    twitter: "https://x.com/RichMediaSocial/"
}

function joinClasses(...args) {
    return args.map((arr, str) => (str && typeof (str) === 'string' ? [...arr, ...str.split(/\s+/)] : arr), []).join(" ");
}
</script>
